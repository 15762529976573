<template>
  <div id="reports">
    <v-app-bar v-if="isLoggedIn" app>
      <v-toolbar-title>Application FORM</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="tablesToExcel()">
        <v-icon left>la-download</v-icon>
        <span>تحميل</span>
      </v-btn>
    </v-app-bar>
    <v-data-table v-if="isLoggedIn" :items-per-page="-1" :items="data" :headers="dataHeader"></v-data-table>
    <v-card class="pa-10" v-if="!isLoggedIn">
      <v-text-field outlined label="USERNAME" v-model="login.username"></v-text-field>
      <v-text-field outlined label="PASSWORD" v-model="login.password"></v-text-field>
      <br />
      <v-btn @click="submitlogin()" block color="primary" x-large>LOGIN</v-btn>
    </v-card>
  </div>
</template>

<script>
import * as XLSX from "xlsx-js-style/dist/xlsx.bundle";
export default {
  data: () => ({
    data: [],
    username: "admin",
    password: "78789898",
    login: {
      username: "",
      password: "",
    },
    isLoggedIn: false,
    dataHeader: [
      { text: "رقم الهاتف", value: "phone" },
      { text: "الاسم الكامل", value: "name" },
      { text: "السكن", value: "location" },
      { text: "نوع التخفيض", value: "examNumber" },
      { text: "القسم", value: "section" },
      { text: "المعدل بعد الاضافة", value: "degree" },
      // { text: "الرمز السري", value: "examPass" },
    ],
  }),
  created: function () {
    this.fetch();
    if (localStorage.getItem("login") == "true") {
      this.isLoggedIn = true;
    }
  },
  methods: {
    fetch() {
      this.$http.get("forms?auth=AzdAddsaUUsaMjjjdajAu2ww87718").then((res) => {
        this.data = res.data;
      });
    },
    submitlogin() {
      if (
        this.login.username == this.username &&
        this.login.password == this.password
      ) {
        this.isLoggedIn = true;
        localStorage.setItem("login", true);
      } else {
        this.$toast.open({
          type: "warning",
          message: "المعلومات خاطئة",
          duration: 3000,
        });
      }
    },
    tablesToExcel() {
      this.showSDollar = true;
      setTimeout(() => {
        var table_elt = document.getElementsByClassName(
          "v-data-table__wrapper"
        )[0];
        var wb = XLSX.utils.table_to_book(table_elt, { raw: true });
        if (wb.Workbook) {
          wb.Workbook.Views[0]["RTL"] = true;
        } else {
          wb.Workbook = {};
          wb.Workbook["Views"] = [{ RTL: true }];
        }

        // Package and Release Data (`writeFile` tries to write and save an XLSB file)
        XLSX.writeFile(wb, "Report.xlsx");
        setTimeout(() => {
          this.showSDollar = false;
        }, 1000);
      }, 500);
    },
  },
};
</script>

<style></style>